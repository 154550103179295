import {
  call,
  put,
  select,
  takeLatest,
  take,
} from 'redux-saga/effects'
import _ from 'lodash'
import { Types, Creators as PlanManagementActions } from '../../reducers/api/planManagement'
import { Creators as RPMActions } from '../../reducers/rpm'
import { Creators as AppActions, Types as AppTypes } from '../../reducers/app'
import { Creators as UserActions } from '../../reducers/user'
import { Creators as NavigationActions } from '../../reducers/navigation'
import { createQueryString } from '../../../utils/utilities'
import { resolveMessage } from '../../../utils/validationHelpers'


import {
  getAppState,
  getApiPath,
  getPlanDetailsFilters,
  getPlanDetails,
  getPlanID,
  getApprovalDocID,
  getPlanLOB,
  getPlanName,
  getPlanVersion,
  getPlanStatus,
  getCurrentSection,
  getPlanHierarchyData,
  getUserState,
  isMenuEmpty,
} from '../../reducers/selectors'
import { fetchPost, fetchPut, fetchPatch } from '../../../utils/fetchApi'
import { convertStringToSnakeCase, convertStrToDateObj, sortObjByStrField } from '../../../utils/utilities'
import rowCellInfo from '../../../pages/private/planDesignManagement/data/rowCellInfo'
import moment from 'moment'
import sectionMappings from '../../../pages/private/planDesignManagement/planDetails/sectionMappings'
import { validateNumericField } from '../../../utils/validationHelpers'

export default [
  getNewPlanWatcher,
  savePlanWatcher,
  getBenefitPlanDataWatcher,
  getPlanDesignLookupWatcher,
  copyPlanWatcher,
  benefitPlanSendForReviewWatcher,
  // benefitPlanUpdateSubdocsWatcher,
  createNewPlanVersionWatcher,
]

/* WATCHERS */
function* getNewPlanWatcher() {
  yield takeLatest(Types.GET_PLAN, getPlanHandler)
}
function* savePlanWatcher() {
  yield takeLatest(Types.SAVE_PLAN, savePlanHandler)
}
function* copyPlanWatcher() {
  yield takeLatest(Types.COPY_PLAN, copyPlanHandler)
}
function* getBenefitPlanDataWatcher() {
  yield takeLatest(Types.GET_BENEFIT_PLAN_DATA, getBenefitPlanDataHandler)
}

function* getPlanDesignLookupWatcher() {
  yield takeLatest(Types.GET_PLAN_DESIGN_LOOKUP_DATA, getPlanDesignLookupHandler)
}

function* benefitPlanSendForReviewWatcher() {
  yield takeLatest(Types.BENEFIT_PLAN_SEND_FOR_REVIEW, benefitPlanSendForReviewHandler)
}

function* createNewPlanVersionWatcher() {
  yield takeLatest(Types.CREATE_NEW_PLAN_VERSION, createNewPlanVersionHandler)
}

// function* benefitPlanUpdateSubdocsWatcher() {
//   yield takeLatest(Types.BENEFIT_PLAN_UPDATE_SUBDOCS, benefitPlanUpdateSubdocsHandler)
// }

function* getOnlyPlanData({ payload }) {
  const { serviceUrl } = yield select(getAppState)
  const { api_path } = yield select(getApiPath, 'create-benefit-plan')
  const url = `${serviceUrl}${api_path}`
  const planID = yield select(getPlanID)
  const planVersion = yield select(getPlanVersion)
  const approvalDocID = yield select(getApprovalDocID)
  const isEditMode = !!planID

  const selectedSection = convertStringToSnakeCase(payload.toLowerCase())

  const reqBody = {
    plan_id: isEditMode ? planID : '',
    section: selectedSection,
    plan_version: isEditMode ? planVersion : '',
    approval_doc_id: isEditMode ? approvalDocID : '',
  }
  const formRendererSections = getFormRendererSections('benefitPlan')
  let data
  const res = yield call(fetchPost, url, reqBody)
  data = res.data
  const formBuilderData = data[0].details[selectedSection].fields
  const formData = data[0]?.model_data
  if (selectedSection === 'utilization_management') {
    formData['programRanks'] = formData.programs.map(p => p)
    formData['qlRanks'] = formData.clinical_ql.map(p => p)
    formData['stepTherapyRanks'] = formData.clinical_step_therapy.map(p => p)
    formData['paRanks'] = formData.clinical_pa.map(p => p)
  }
  // convertDropdownObjectsToArray(formBuilderData, formData)
  return data[0]
}

/* HANDLERS */
function* getPlanHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'create-benefit-plan')
    const url = `${serviceUrl}${api_path}`
    const planID = yield select(getPlanID)
    const planVersion = yield select(getPlanVersion)
    const approvalDocID = yield select(getApprovalDocID)
    const userState = yield select(getUserState)
    const { hierarchyAccess = [] } = userState
    const isEditMode = !!planID
    const selectedSection = payload ? convertStringToSnakeCase(payload.toLowerCase()) : 'plan_design_details'
    yield put(PlanManagementActions.setCurrentSection({ data: selectedSection }))
    const reqBody = {
      plan_id: isEditMode ? planID : '',
      section: selectedSection,
      plan_version: isEditMode ? planVersion : '',
      approval_doc_id: isEditMode ? approvalDocID : '',
      cag: hierarchyAccess,
    }
    const formRendererSections = getFormRendererSections('benefitPlan')
    let data

    if (formRendererSections.includes(selectedSection)) {
      const res = yield call(fetchPost, url, reqBody)
      data = res.data
      let sections = Object.keys(data[0].details)
      yield put(PlanManagementActions.setFormSections({ data: sections }))
      const formBuilderData = data[0].details[selectedSection].fields
      const formData = data[0]?.model_data
      const { carrier, account, group } = formData || {}

      if (carrier)
        yield put(PlanManagementActions.setFilters({
          data: { filterName: 'carrier', filterValue: carrier },
        }))
      if (account)
        yield put(PlanManagementActions.setFilters({
          data: { filterName: 'account', filterValue: account },
        }))
      if (group)
        yield put(PlanManagementActions.setFilters({
          data: { filterName: 'group', filterValue: group },
        }))
      // TODO : remove hard coding and move to backend eventually
      if (selectedSection === 'utilization_management') {
        yield put(PlanManagementActions.setFormSections({ data: sections }))
        formData['programRanks'] = formData.programs.map(p => p)
        formData['qlRanks'] = formData.clinical_ql.map(p => p)
        formData['stepTherapyRanks'] = formData.clinical_step_therapy.map(p => p)
        formData['paRanks'] = formData.clinical_pa.map(p => p)
      }
      else if (selectedSection === 'deductibles') {
        const {
          benefit_effective_start_date,
          benefit_effective_end_date,
          benefit_period_type,
          deductible_satisfaction,
          individual_deductible,
          family_deductible,
          oop_satisfaction,
          individual_oop,
          family_oop,
          deductible_oop_logic,
        } = data[0].model_data

        const accumulator_period = [{
          benefit_effective_start_date,
          benefit_effective_end_date,
          benefit_period_type,
          deductible_satisfaction,
          individual_deductible,
          family_deductible,
          oop_satisfaction,
          individual_oop,
          family_oop,
          deductible_oop_logic,
        }]
        yield put(PlanManagementActions.setAccumulatorPeriodData({ data: accumulator_period }))
      }
      convertDropdownObjectsToArray(formBuilderData, formData, selectedSection)
      yield put(PlanManagementActions.setPlan({ data: data[0] }))
    } else if (selectedSection === 'utilization_management') {
      const res = yield call(fetchPost, url, reqBody)
      data = res.data
      let sections = Object.keys(data[0].details)
      yield put(PlanManagementActions.setFormSections({ data: sections }))
      yield put(PlanManagementActions.setPlan({ data: data[0] }))

    } else if (selectedSection === 'application_only') {
      const res = yield call(fetchPost, url, reqBody)
      data = res.data
      if (!data?.length) return
      let sections = Object.keys(data[0].details)
      yield put(PlanManagementActions.setFormSections({ data: sections }))
      yield put(PlanManagementActions.setPlan({ data: data[0] }))
    } else if (selectedSection === 'drug_utilization_review') {
      const res = yield call(fetchPost, url, reqBody)
      data = res.data
      if (!data?.length) return
      let sections = Object.keys(data[0].details)
      yield put(PlanManagementActions.setFormSections({ data: sections }))
      yield put(PlanManagementActions.setPlan({ data: data[0] }))
    }
    else if (selectedSection === 'plan_design_details') {
      const res = yield call(fetchPost, url, reqBody)
      data = res.data

      let sections = Object.keys(data[0].details)
      yield put(PlanManagementActions.setFormSections({ data: sections }))

      const formData = data[0]?.model_data
      const { hierarchy } = formData || {}
      const defaultCarrier = Object.values(userState?.userHierarchy)[0][0]?.children[0]?.children[0]
      let carrier = {
        name: defaultCarrier?.carrier_name,
        value: defaultCarrier?.carrier_id,
        key: defaultCarrier?.key,
        path: [Object.values(userState.userHierarchy)[0][0].children[0]?.key, Object.values(userState.userHierarchy)[0][0]?.key, defaultCarrier?.key]
      }
      const account = {}
      const group = {}

      yield put(PlanManagementActions.setPlanDesignData({ data: data[0]?.model_data }))
      yield put(PlanManagementActions.setPlan({ data: data[0] }))
    }
    // else if (selectedSection === 'hierarchy') {
    //   const res = yield call(fetchPost, url, reqBody)
    //   data = res.data
    //   yield put(PlanManagementActions.setPlan({ data: data[0] }))
    // }
    else if (selectedSection === 'formulary_copay' || selectedSection === 'custom_copay') {
      const res = yield call(fetchPost, url, reqBody)
      data = res.data
      const copayData = constructCopayData(data[0], selectedSection)
      if (selectedSection === 'formulary_copay') {
        yield put(PlanManagementActions.setFormularyCopayData({ data: copayData }))
      } else {
        yield put(PlanManagementActions.setCustomCopayData({ data: copayData }))
      }
      yield put(PlanManagementActions.setPlan({ data: data[0] }))
    } else if (selectedSection === 'custom_messaging') {
      const res = yield call(fetchPost, url, reqBody)
      data = res.data
      const customMessagingData = constructCustomMessagingData(data[0])
      yield put(PlanManagementActions.setCustomMessagingData({ data: customMessagingData }))
      yield put(PlanManagementActions.setPlan({ data: data[0] }))
    } else if (selectedSection === 'limitations') {
      const planData = yield getOnlyPlanData({ payload: 'listing_hierarchy' })
      const res = yield call(fetchPost, url, reqBody)
      data = res.data
      const benefitLimitationData = constructBenefitLimitationData(data[0])
      if (data[0] && data[0].model_data) data[0].model_data['programs'] = planData?.model_data['programs'] || []
      if (data[0] && data[0].model_data) data[0].model_data['pharmacy_network_tier_rank'] = planData?.model_data['pharmacy_network_tier_rank'] || []
      yield put(PlanManagementActions.setBenefitLimitation({ data: benefitLimitationData }))
      yield put(PlanManagementActions.setPlan({ data: data[0] }))
    } else if (selectedSection === 'grandfathering') {
      const res = yield call(fetchPost, url, reqBody)
      data = res.data
      const grandfatheringData = constructGrandfatheringData(data[0])
      yield put(PlanManagementActions.setGrandfatheringData({ data: grandfatheringData }))
      yield put(PlanManagementActions.setPlan({ data: data[0] }))
    } else if (selectedSection === 'listing_hierarchy') {
      const res = yield call(fetchPost, url, reqBody)
      data = res.data
      const {
        um_ranking,
        tiers,
        programs,
        clinical_pa,
        clinical_ql,
        clinical_step_therapy,
      } = data[0].model_data
      const listingHierarchyData = {
        um_ranking,
        tiersRanking: tiers,
        programsRanking: programs,
        clinicalPARanking: clinical_pa,
        clinicalQLRanking: clinical_ql,
        clinicalSTRanking: clinical_step_therapy,
      }
      yield put(PlanManagementActions.setListingHierarchyData({ data: listingHierarchyData }))
      yield put(PlanManagementActions.setPlan({ data: data[0] }))
    } else if (selectedSection === 'daw_settings') {
      const res = yield call(fetchPost, url, reqBody)
      data = res.data
      const {
        psc_settings,
        brand_covered_upto_generic_copay_flag,
      } = data[0].model_data
      const dawSettingsData = {
        psc_settings,
        brand_covered_upto_generic_copay_flag: brand_covered_upto_generic_copay_flag === 'Y',
      }
      yield put(PlanManagementActions.setDawSettingsData({ data: dawSettingsData }))
      yield put(PlanManagementActions.setPlan({ data: data[0] }))
    } else if (selectedSection === 'pricing_mixmaster') {
      const res = yield call(fetchPost, url, reqBody)
      data = res.data
      const mixMasterData = constructMixMasterData(data[0])
      yield put(PlanManagementActions.setPricingMixMaster({ data: mixMasterData }))
      yield put(PlanManagementActions.setPlan({ data: data[0] }))
    } else if (selectedSection === 'transition') {
      const res = yield call(fetchPost, url, reqBody)
      data = res.data
      yield put(PlanManagementActions.setTransitionData({ data: constructTranistionData(data[0]) }))
      yield put(PlanManagementActions.setPlan({ data: data[0] }))
    }
    // else if (selectedSection === 'rewards_penalties') {
    //   const res = yield call(fetchPost, url, reqBody)
    //   data = res.data
    //   const rewardsPenaltiesData = constructRewardsPenaltiesData(data[0])
    //   yield put(PlanManagementActions.setRewardsPenaltiesData({ data: rewardsPenaltiesData }))
    //   yield put(PlanManagementActions.setPlan({ data: data[0] }))
    // } 
    else if (selectedSection === 'compounds') {
      const res = yield call(fetchPost, url, reqBody)
      data = res.data
      console.log(data)
      const compoundsData = constructCompoundsData(data[0])
      yield put(PlanManagementActions.setCompoundsData({ data: compoundsData }))
      yield put(PlanManagementActions.setPlan({ data: data[0] }))
    } else if (selectedSection === 'pharmacy_networks') {
      const res = yield call(fetchPost, url, reqBody)
      data = res.data
      const {
        networks,
        network_edits,
        pharmacy_network_rank,
        pharmacy_network_tier_rank,
        pharmacy_network_edit_rank,
      } = data[0].model_data

      const pharmacy_network = {
        networks,
        network_edits,
        pharmacy_network_rank,
        pharmacy_network_tier_rank,
        pharmacy_network_edit_rank,
      }
      yield put(PlanManagementActions.setPharmacyNetworkData({ data: pharmacy_network }))
      yield put(PlanManagementActions.setPlan({ data: data[0] }))
    } else if (selectedSection === 'review') {
      const res = yield call(fetchPost, url, reqBody)
      data = res.data
      const { model_data } = data[0]
      yield put(PlanManagementActions.setBenefitReviewData({ data: model_data }))
      yield put(PlanManagementActions.setPlan({ data: data[0] }))
    } else if (selectedSection === 'claim_processing_config') {
      const res = yield call(fetchPost, url, reqBody)
      data = res.data
      // const { model_data } = data[0]
      // yield put(PlanManagementActions.setBenefitReviewData({ data: model_data }))
      yield put(PlanManagementActions.setPlan({ data: data[0] }))
    }
    else if (selectedSection === 'phases') {
      const res = yield call(fetchPost, url, reqBody)
      data = res.data
      const phasesData = constructPhasesData(data[0])
      yield put(PlanManagementActions.setPhasesData({ data: phasesData }))
      yield put(PlanManagementActions.setPlan({ data: data[0] }))
    }
    else if (selectedSection === 'default_copay') {
      const res = yield call(fetchPost, url, reqBody)
      data = res.data
      yield put(PlanManagementActions.setPlan({ data: data[0] }))
      yield put(PlanManagementActions.setCustomCopayData({ data: data[0] }))
    }
  } catch (err) {
    console.log('getPlanHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Get Plan Failed',
      copy: err,
    }
    if (err.includes('Formulary is not mapped')) {
      transitionalPortal.header = ''
      transitionalPortal.copy = 'A Formulary has not been defined. In order to define cost share, please define a formulary on the Plan Design Details tab.'
    }
    if (err.includes('Programs is not mapped')) {
      transitionalPortal.header = ''
      transitionalPortal.copy = 'No Programs have been defined. In order to define custom cost share, please define one or more programs on the Utilization Management tab'
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* savePlanHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const userState = yield select(getUserState)
    const { api_path } = yield select(getApiPath, 'create-benefit-plan')
    let url = `${serviceUrl}${api_path}`
    const currentPlanID = yield select(getPlanID)
    const currentApprovalDocID = yield select(getApprovalDocID)
    const currentPlanName = yield select(getPlanName)
    const currentPlanVersion = yield select(getPlanVersion)
    const currentPlanStatus = yield select(getPlanStatus)
    const currentPlanLOB = yield select(getPlanLOB)
    // const hierarchyData = yield select(getPlanHierarchyData)
    // if (!hierarchyData?.hierarchyIDs) return 'Please select a hierarchy'
    const isEditMode = !!currentPlanID

    // const { carrier, account, group } = yield select(getPlanDetailsFilters)
    const { details } = yield select(getPlanDetails)
    let section = yield select(getCurrentSection)
    const { formData } = payload
    let { customFormData } = payload
    const formRendererSections = getFormRendererSections('benefitPlan')
    let model_data = {}
    let formDataCopy = {}
    if (formRendererSections.includes(section)) {
      if (section === 'deductibles') {
        formDataCopy = { ...customFormData['data'] }
        const accumulator_period = formDataCopy?.accumulator_period?.filter(e => Object.values(e).some(value => Boolean(value?.toString()))) || []
        const validation = accumulator_period.map(e => Object.values(e).every(value => Boolean(value)))
        if (!validation.every(Boolean))
          throw new Error(resolveMessage("val_all_fields_are_required").message)
        formDataCopy['accumulator_period'] = accumulator_period
      }
      const formattedPayload = formatPayloadForMultiSelectDropdowns({ ...formData, ...formDataCopy }, details, section)
      const formattedPayload2 = formatPayloadForSingleSelectDropdowns(formattedPayload, details)
      model_data = {
        ...formattedPayload2,
      }
    } else if (section === 'application_only') {
      const {
        app_pharmacy_popup_message_type,
        message, no_access_message,
      } = formData?.app_pharmacy_popup_message || {}
      const { app_max_incentive_credit,
        max_incentive_credit_per_intent } = formData
      const messageType = !!app_pharmacy_popup_message_type
      const popupMessage = !!message
      const popupNoMessage = !!no_access_message
      if (formData?.flipt_marketing_configuration?.length &&
        (formData?.flipt_marketing_configuration[0]?.message_on_off ||
          formData?.flipt_marketing_configuration[0]?.image_location ||
          formData?.flipt_marketing_configuration[0]?.link)) {
        if (!formData?.flipt_marketing_configuration[0]?.message_on_off ||
          !formData?.flipt_marketing_configuration[0]?.image_location ||
          !formData?.flipt_marketing_configuration[0]?.link) {
          throw new Error(resolveMessage("val_all_the_details_are_required_to_save_banner").message)
        }
      }
      if (messageType && (!popupMessage || !popupNoMessage)) {
        throw new Error(resolveMessage("val_popup_message_should_not_be_empty").message)
      }
      if (!app_max_incentive_credit)
        formDataCopy['app_max_incentive_credit'] = ''
      if (!max_incentive_credit_per_intent)
        formDataCopy['max_incentive_credit_per_intent'] = ''
      const formattedPayload = formatPayloadForMultiSelectDropdowns({ ...formData, ...formDataCopy }, details, section)
      const formattedPayload2 = formatPayloadForSingleSelectDropdowns(formattedPayload, details)
      model_data = {
        ...formattedPayload2,
      }
    }
    // else if (section === 'plan_design_details' || section === 'hierarchy') {
    else if (section === 'plan_design_details') {
      let planParams = { ...customFormData }

      if (!planParams.created_by) planParams.created_by = userState.uuid
      // delete planParams.planInfo
      model_data = { ...planParams }
      model_data.is_hierarchy_global = true
      if (section == 'hierarchy') {
        return
      }
    }
    else if (['formulary_copay', 'custom_copay', 'default_copay'].includes(section)) {
      const { data: copayData = [] } = customFormData
      const benefitCopay = _.omit(formData, ['carrier', 'account', 'group'])
      const { copays } = benefitCopay
      let copay
      const existingTierData = getCopayTierData(copays)
      const existingProgramData = getCopayProgramData(copays)
      if (section !== 'default_copay') {
        copayData.forEach(formData => {
          if (formData?.phaseData?.length > 0) {
            formData.phaseData.forEach(phase => {
              validateCopayData(phase)
            })
          } else {
            validateCopayData(formData)
          }
        })
      }
      if (section === 'formulary_copay') {
        const updatedTierData = formatCopayData(copayData, section, existingTierData, '')
        copay = [...updatedTierData, ...existingProgramData]
      } else if (section === 'custom_copay') {
        const updatedProgramData = formatCopayData(copayData, section, existingProgramData, '')
        copay = [...updatedProgramData, ...existingTierData]
      } else if (section === 'default_copay') {
        const newCustomFormData = { data: { ...copayData }, prevCopays: customFormData.prevCopays }
        const filteredPrevCopayData = filterOutDefaultCopay(newCustomFormData.prevCopays)
        const formattedDefaultData = formatDefaultCopayData(newCustomFormData?.data, currentPlanName)
        copay = formattedDefaultData ? [formattedDefaultData] : []
        if (filteredPrevCopayData?.length) {
          copay = formattedDefaultData ? [formattedDefaultData, ...filteredPrevCopayData] : filteredPrevCopayData
        }
      }

      model_data = {
        ...benefitCopay,
        plan_id: isEditMode ? currentPlanID : '',
        plan_name: isEditMode ? currentPlanName : '',
        copays: copay,
      }
    }
    else if (section === 'drug_utilization_review') {
      if (customFormData?.dur_configuration?.conditions === 'Yes' && !customFormData?.dur_configuration?.doc_id) {
        throw new Error(resolveMessage("val_config_name_required_if_applied_yes").message)
      }
      model_data = customFormData
    }
    else if (section === 'custom_messaging') {
      const { data: { custom_messaging: customMessagingData } = {} } = customFormData
      if (customMessagingData !== undefined) {
        model_data = {
          custom_messaging: customMessagingData
        }
      }
    } else if (section === 'limitations') {
      const { error = '', data = {}, data: { plan_sponsor_cap, plan_sponsor_cap_applicable } = {} } = customFormData
      if (error) {
        throw new Error(error)
      }
      let invalidMaxNoOfFills = false
      const number_fields = ['min_day_supply', 'max_day_supply', 'days_to_search_back', 'days_to_search_back', 'amount', 'rank', 'max_no_of_fills']
      const limitationData = data?.limitations?.map((row) => {
        if (!row.days_to_search_back) row.days_to_search_back = '365'
        if (row.max_no_of_fills && row.max_no_of_fills !== 'All' && !validateNumericField(row.max_no_of_fills)) {
          invalidMaxNoOfFills = true;
        }
        number_fields.forEach((field) => {
          row[field] = (Number.isNaN(row[field]) || row[field] === '') ? null : row[field] = Number(row[field])
          // row[field] = parseFloat(row[field], 10)
        })
        const { minimum_age, maximum_age } = row

        if (!!minimum_age && isNaN(minimum_age)) throw new Error(resolveMessage("val_must_be_a_number", { screen: "Limitations", catgeory: "Minimum", field: "Age" })?.message)
        if (!!maximum_age && isNaN(maximum_age)) throw new Error(resolveMessage("val_must_be_a_number", { screen: "Limitations", catgeory: "Maximum", field: "Age" })?.message)
        row['age'] = {
          min: isNaN(parseFloat(minimum_age)) ? null : parseFloat(minimum_age),
          max: isNaN(parseFloat(maximum_age)) ? null : parseFloat(maximum_age),
        }

        if (row?.age?.min < 0 || row?.age?.min > 150) throw new Error(resolveMessage("val_category_is_out_of_bounds", { screen: "Limitations", catgeory: "Minimum", field: "Age" })?.message)
        if (row?.age?.max < 0 || row?.age?.max > 150) throw new Error(resolveMessage("val_category_is_out_of_bounds", { screen: "Limitations", catgeory: "Maximum", field: "Age" })?.message)

        return row
      })
      if (invalidMaxNoOfFills) {
        const errorMessage = resolveMessage("val_max_number_of_fills_can_be_all_or_numeric_only")
        const transitionalPortal = {
          header: errorMessage?.header,
          copy: errorMessage?.message,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
        return
      }
      let planSponsorCapData
      if (!!plan_sponsor_cap_applicable) {
        const requiredFormFields = ['cap_type']
        let planLevelFound = false
        plan_sponsor_cap.forEach((row) => {
          requiredFormFields.forEach((field) => {
            if (!row || !row[field] || (row[field] instanceof Array && row[field].length === 0)) throw new Error(resolveMessage("val_required_data_element_field", { screen: "Plan Sponsor Cap", field: field })?.message)
            validateFields(details[section]?.fields, row)
            if (field === 'cap_type' && row[field] === 'plan_level') {
              if (planLevelFound) throw new Error(resolveMessage("val_only_one_plan_level_cap_type_can_be_defined", { screen: "Plan Sponsor Cap" })?.message)
              planLevelFound = true
              // if (row[field].length > 1) throw new Error(`Plan Sponsor Cap: Cap Type cannot include both Plan Level and Programs`)
            }
          })
          const { plan_code, start_date, end_date, sponsor_cap } = row
          if (!!start_date && !!end_date && start_date > end_date) throw new Error(resolveMessage("val_date_cannot_be_less_than_start_date", { screen: "Plan Sponsor Cap" })?.message)
          if (Number.isNaN(Number(sponsor_cap))) throw new Error(resolveMessage("val_sponsor_cap_must_be_a_numeric_value", { screen: "Plan Sponsor Cap" })?.message)
          const form_number_fields = ['sponsor_cap']
          form_number_fields.forEach((field) => {
            row[field] = (Number.isNaN(row[field]) || row[field] === '') ? null : Number(row[field])
          })
          const form_date_fields = ['start_date', 'end_date']
          form_date_fields.forEach((field) => {
            row[field] = row[field] ? moment(row[field]).format('YYYY-MM-DD') : ''
          })
          if (typeof plan_code === 'string' || plan_code === undefined) row['plan_code'] = (!!plan_code && plan_code.length > 0) ? plan_code.split(',').map(x => x.trim()) : []
        })
        planSponsorCapData = [...plan_sponsor_cap]
      } else if (plan_sponsor_cap_applicable === false) {
        planSponsorCapData = []
      }

      model_data = {
        limitations: limitationData,
        plan_sponsor_cap: planSponsorCapData,
      }


    } else if (section === 'listing_hierarchy') {
      const {
        um_ranking,
        tiersRanking,
        programsRanking,
        clinicalPARanking,
        clinicalQLRanking,
        clinicalSTRanking,
      } = customFormData

      model_data = {
        ...formData,
        um_ranking,
        programs: programsRanking,
        clinical_pa: clinicalPARanking,
        clinical_ql: clinicalQLRanking,
        clinical_step_therapy: clinicalSTRanking,
        tiers: tiersRanking,
      }
    }
    else if (section === 'pharmacy_networks') {
      const { networks, network_edits, pharmacy_network_rank, pharmacy_network_tier_rank, pharmacy_network_edit_rank } = customFormData

      model_data = {
        ...formData,
        networks,
        network_edits,
        pharmacy_network_rank,
        pharmacy_network_tier_rank,
        pharmacy_network_edit_rank
      }

    } else if (section === 'daw_settings') {
      const {
        psc_settings,
        brand_covered_upto_generic_copay_flag,
      } = customFormData

      model_data = {
        ...formData,
        psc_settings,
        brand_covered_upto_generic_copay_flag: brand_covered_upto_generic_copay_flag ? 'Y' : 'N',
      }
    } else if (section === 'pricing_mixmaster') {
      const { error = '', data = {} } = customFormData
      if (error) {
        throw new Error(error)
      }
      if (!!data) {
        const { price_source, otc_price_source } = data
        if (!!price_source) {
          price_source.forEach(row => {
            const listFields = ['application']
            listFields.forEach(field => {
              if (row.hasOwnProperty(field)) {
                if (typeof row[field] === 'string') row[field] = row[field] === '' ? Array.from(row[field]) : [row[field]]
              }
            })
          })
        }
        if (!!otc_price_source) {
          otc_price_source.forEach(row => {
            const listFields = ['application']
            listFields.forEach(field => {
              if (row.hasOwnProperty(field)) {
                if (typeof row[field] === 'string') row[field] = row[field] === '' ? Array.from(row[field]) : [row[field]]
              }
            })
          })
        }
      }
      model_data = {
        ...data,
      }
    } else if (section === 'transition') {
      const { data = {} } = customFormData
      if (data.hasOwnProperty("transitionData")) {
        const { transitionData } = data;
        if (transitionData?.length) {
          let fieldError = false
          transitionData.forEach((transitionData) => {
            delete transitionData.exclusion_list
            const test = Object.values(transitionData).every((value) => value !== '')
            if (!test) {
              fieldError = true
            }
          })
          if (fieldError) {
            const errorMessage = resolveMessage("val_please_add_all_fields_to_proceed");
            const transitionalPortal = {
              header: errorMessage?.header,
              copy: errorMessage?.message,
            }
            yield put(AppActions.displayTransitionalPortal(transitionalPortal))
            return;
          }

        }
        model_data = {
          transition_fill: transitionData,
        }
      }
    } else if (section === 'grandfathering') {
      const { data: { grandfathering: grandfatheringData } = {} } = customFormData
      if (grandfatheringData !== undefined) {
        grandfatheringData.forEach(row => {
          validateFields(details[section]?.fields, row)
          const form_number_fields = ['lookback_period']
          form_number_fields.forEach(field => {
            if (Number.isNaN(Number(row[field]))) throw new Error(resolveMessage("val_grandfathering_field_must_be_a_numeric_value", { field: field }).message)
            row[field] = (Number.isNaN(row[field]) || row[field] === '') ? null : Number(row[field])
          })
          const { gpi_match_level } = row
          if (gpi_match_level && gpi_match_level.startsWith('GPI')) {
            const gpi_number = parseInt(gpi_match_level.match(/\d+$/))
            const formattedGPI = new Array(gpi_number + 1).join('X') + (gpi_number !== 14 ? '*' : '')
            row['gpi_match_level'] = formattedGPI
          }
          const { effective_start_date, effective_end_date } = row
          const now = new Date(Date.now())
          if (!!effective_start_date) {
            row['effective_start_date'] = moment(effective_start_date).startOf('day').format('YYYY-MM-DD HH:mm:ss')
          }
          if (!!effective_end_date) {
            row['effective_end_date'] = moment(effective_end_date).endOf('day').format('YYYY-MM-DD HH:mm:ss')
          }
          const { effective_start_date: formatted_effective_start_date, effective_end_date: formatted_effective_end_date } = row

          //if (!!formatted_effective_start_date && !(formatted_effective_start_date < moment(now).startOf('day').format('YYYY-MM-DD HH:mm:ss'))) throw new Error(`Grandfathering: Effective Start Date must be before today`) # Disabled Validation for Welcoming season
          if (!!formatted_effective_start_date && !!formatted_effective_end_date && formatted_effective_start_date > formatted_effective_end_date) throw new Error(resolveMessage("val_effective_end_date_cannot_be_before_effective_start_date").message)
        })
        model_data = {
          grandfathering: grandfatheringData,
        }
      }
    }
    else if (section === 'compounds') {
      const { compoundsData } = customFormData
      const { compound_coverage,
        allow_scc8,
        compound_pricing_options,
        compound_pharmacy_exclusions,
        maximum_price_action,
        minimum_compounds_price,
        maximum_compounds_price } = compoundsData
      if ((compound_coverage == 'all' || compound_coverage == 'atleast_1') && (!compound_pricing_options || !allow_scc8)) {
        const errorMessage = resolveMessage("val_missing_scc_code_8_values")
        const transitionalPortal = {
          header: errorMessage?.header,
          copy: errorMessage?.message,
        }
        yield put(AppActions.displayTransitionalPortal(transitionalPortal))
        return;
      }
      let copayData = []
      copayData = compoundsData ? [compoundsData?.[0]] : []
      const updatedProgramData = formatCompoundsCopayData(copayData, section, [], currentPlanName)
      let copay = [...updatedProgramData]

      const benefitCopay = {
        approval_doc_id: formData.approval_doc_id,
        create_date: formData.create_date,
        created_by: formData.created_by,
        effective_end_date: formData.effective_end_date,
        effective_start_date: formData.effective_start_date,
        plan_id: formData.plan_id,
        plan_name: formData.plan_name,
        plan_version: formData.plan_version,
        status: formData.status,
        update_date: formData.update_date,
        updated_by: formData.updated_by,
        allow_scc8: allow_scc8,
      }
      model_data = {
        ...benefitCopay,
        plan_id: isEditMode ? currentPlanID : '',
        plan_name: isEditMode ? currentPlanName : '',
        copays: copay,
      }
      const reqBody = {
        plan_id: isEditMode ? currentPlanID : '',
        section,
        plan_version: isEditMode ? currentPlanVersion : '',
        model_data,
      }
      const { data: result } = yield call(fetchPut, url, { ...reqBody })
      const { benefit_details, cb_data } = result[0]
      const data = [benefit_details]

      model_data = {
        ...formData,
        compound_coverage,
        allow_scc8,
        compound_pricing_options,
        compound_pharmacy_exclusions,
        maximum_price_action,
        minimum_compounds_price,
        maximum_compounds_price
      }
      delete model_data["copays"]

    } else if (section === 'rewards_penalties') {
      const { alternate_price_type, display_coinsurance_penalty_together, display_retail_drugsub_rewards, rbp_cp_apply_rewards_no_intent, rbp_fallback_zone_pricing, rbp_penalty_oop_accumulation, rbp_penalty_predeductible_accumulation, brand_covered_upto_generic_copay_flag } = customFormData
      model_data = {
        ...formData, alternate_price_type, display_coinsurance_penalty_together, display_retail_drugsub_rewards, rbp_cp_apply_rewards_no_intent, rbp_fallback_zone_pricing, rbp_penalty_oop_accumulation, rbp_penalty_predeductible_accumulation, brand_covered_upto_generic_copay_flag
      }
    } else if (section === 'claim_processing_config') {
      if (!customFormData['pa_match_level'])
        customFormData['pa_match_level'] = 'GPI14'
      const gpi_size = parseInt(customFormData['pa_match_level'].replace(/^\D+/g, ''))
      if (!customFormData['bmn_override_match_level'])
        customFormData['bmn_override_match_level'] = 'GPI14'
      const bmnGpiSize = parseInt(customFormData['bmn_override_match_level'].replace(/^\D+/g, ''))
      if (!customFormData['drug_coverage_override_match_level'])
        customFormData['drug_coverage_override_match_level'] = 'GPI14'
      const drugCoverageGpiSize = parseInt(customFormData['drug_coverage_override_match_level'].replace(/^\D+/g, ''))
      customFormData['pa_override_match'] = {
        gpi: [...Array(gpi_size).fill('X'), '*'].join(''),
        brand_generic: customFormData['brand_generic'],
      }
      customFormData['bmn_override_match'] = {
        gpi: [...Array(bmnGpiSize).fill('X'), '*'].join(''),
        brand_generic: customFormData['bmn_brand_generic'],
      }
      customFormData['drug_coverage_override_match'] = {
        gpi: [...Array(drugCoverageGpiSize).fill('X'), '*'].join(''),
        brand_generic: customFormData['dc_brand_generic'],
      }
      if (customFormData?.tpa_id_processing_config?.length) {
        let fieldError = false
        customFormData?.tpa_id_processing_config.forEach((data) => {
          if ((data.allow_tpa_id_processing === 'Yes' && data.end_date && !data.message) || (data.allow_tpa_id_processing === 'No' && !data.message)) {
            fieldError = true
          }
        })
        if (fieldError) {
          const errorMessage = resolveMessage("val_please_add_message_to_proceed")
          const transitionalPortal = {
            header: errorMessage?.header,
            copy: errorMessage?.message,
          }
          yield put(AppActions.displayTransitionalPortal(transitionalPortal))
          return;
        }

      }
      model_data = { ...customFormData }
    }
    if (section === 'rra') {
      const number_fields = ['rra_daysofsupply_fillcount']
      number_fields.forEach((field) => {
        model_data[field] = (Number.isNaN(model_data[field]) || model_data[field] === '') ? null : model_data[field] = Number(model_data[field])
      })
    } else if (section === 'deductibles') {
      const number_fields = ['individual_deductible_limit', 'family_deductible_limit', 'individual_out_of_pocket_limit', 'family_out_of_pocket_limit']
      number_fields.forEach((field) => {
        model_data[field] = (Number.isNaN(model_data[field]) || model_data[field] === '') ? null : model_data[field] = Number(model_data[field])
      })
      if (['Yearly', 'Quarterly', 'Monthly'].includes(model_data['accumulator_type']) && _.isEmpty(model_data['accumulator_date']))
        throw new Error(resolveMessage("val_accumulator_date_is_required").message)
    } else if (section === 'utilization_management') {
      model_data = { ...customFormData }

      // if (model_data?.programs?.length) {
      //   const hasDuplicate = hasDuplicateProgram(model_data.programs)
      //   if (hasDuplicate) {
      //     throw new Error(`Remove Duplicate Program from grid before saving!`)
      //   }
      // }
      // TODO : remove hard coding and move to backend eventually
      if (model_data?.programs?.length) {
        model_data['programs'] = model_data['programs'].map((p, index) => ({
          ...p,
          rank: index + 1
        }))
      }
      if (model_data?.clinical_ql?.length) {
        model_data['clinical_ql'] = model_data['clinical_ql'].map((p, index) => ({
          ...p,
          rank: index + 1
        }))
      }
      if (model_data?.clinical_pa?.length) {
        model_data['clinical_pa'] = model_data['clinical_pa'].map((p, index) => ({
          ...p,
          rank: index + 1
        }))
      }
      if (model_data?.clinical_step_therapy?.length) {
        model_data['clinical_step_therapy'] = model_data['clinical_step_therapy'].map((p, index) => ({
          ...p,
          rank: index + 1
        }))
      }
    }
    let dataPlanVersion = isEditMode ? currentPlanVersion : ''
    model_data = {
      ...model_data,
      approval_doc_id: isEditMode ? currentApprovalDocID : '',
      plan_version: dataPlanVersion,
      status: currentPlanStatus || 'DRAFT',
    }

    const reqBody = {
      plan_id: isEditMode ? currentPlanID : '',
      section,
      plan_version: dataPlanVersion,
      model_data,
    }

    const { data: result } = yield call(fetchPut, url, { ...reqBody })
    const { benefit_details, cb_data } = result[0]
    const data = [benefit_details]
    const planID = isEditMode ? currentPlanID : data[0].plan_id
    const approvalDocID = isEditMode ? currentApprovalDocID : data[0].approval_doc_id
    const planName = isEditMode ? currentPlanName : data[0].plan_name
    const planLOB = isEditMode ? currentPlanLOB : (data[0].lob || '')
    const planVersion = isEditMode ? currentPlanVersion : data[0]?.plan_version
    const planStatus = isEditMode ? currentPlanStatus : data[0]?.status
    if (section === 'plan_design_details') {
      yield put(PlanManagementActions.setPlanDesignData({ data: data[0] }))
    }
    // if (section === 'rewards_penalties') {
    //   yield put(PlanManagementActions.setRewardsPenaltiesData({ data: data[0] }))
    // }
    if (section === 'compounds') {
      yield put(PlanManagementActions.setCompoundsData({ data: data[0] }))
    }
    if (section === 'pharmacy_networks') {
      yield put(PlanManagementActions.setPharmacyNetworkData({ data: data[0] }))
    }
    if (section === 'pricing_mixmaster') {
      yield put(PlanManagementActions.setPricingMixMaster({ data: data[0] }))
    }
    if (section === 'limitations') {
      const { limitations } = data[0]
      if (limitations)
        yield put(PlanManagementActions.setBenefitLimitation({ data: data[0] }))
    }
    if (section === 'phases') {
      yield put(PlanManagementActions.setPhasesData({ data: data[0] }))
    }
    if (section === 'application_only') {
      const application_only = { details, model_data: { ...model_data, ...data[0] } }
      yield put(PlanManagementActions.setPlan({ data: application_only }))
    }
    if (formRendererSections.includes(section)) {
      const section_info = { details, model_data: { ...model_data, ...data[0] } }
      const formBuilderData = section_info.details[section].fields
      const formData = section_info.model_data
      if (section === 'utilization_management') {
        formData['programRanks'] = formData.programs.map(p => p)
        formData['qlRanks'] = formData.clinical_ql.map(p => p)
        formData['stepTherapyRanks'] = formData.clinical_step_therapy.map(p => p)
        formData['paRanks'] = formData.clinical_pa.map(p => p)
      }
      convertDropdownObjectsToArray(formBuilderData, formData, section)
      yield put(PlanManagementActions.setPlan({ data: section_info }))
    }
    yield put(PlanManagementActions.setPlanID({ data: planID }))
    yield put(PlanManagementActions.setPlanName({ data: planName }))
    yield put(PlanManagementActions.setPlanVersion({ data: planVersion }))
    yield put(PlanManagementActions.setPlanStatus({ data: planStatus }))
    yield put(PlanManagementActions.setPlanLOB({ data: planLOB }))

    const transitionalPortal = {
      header: 'Saved Plan Successfully',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))

    const approvalDocument = {
      module_name: planName || cb_data.plan_name,
      data: {
        [cb_data?.type ?? model_data?.type ?? formData?.type]: cb_data, doc_id: approvalDocID
      },
      module: 'BENEFIT_PLAN_DOC',
      status: 'DRAFT',
    }

    const cb = function* ({ success, response }) {
      if (success) {
        if (!approvalDocID || formData?.status === 'Published' || formData?.status === 'PUBLISHED') {
          const { data: doc_data } = response
          const { id: approval_doc_id } = doc_data[0]
          const { data: result } = yield call(fetchPut, url, { ...reqBody, plan_id: planID, model_data: { ...model_data, approval_doc_id }, plan_version: doc_data[0]?.version })
          const { cb_data } = result[0]
          const approvalDocID = isEditMode ? currentApprovalDocID : cb_data?.approval_doc_id
          yield put(PlanManagementActions.setApprovalDocID({ data: approvalDocID }))
        }
        const { api_path } = yield select(getApiPath, 'update-benefit-plan-by-approval-doc-id-2')
        url = `${serviceUrl}${api_path}`
        const { data } = response
        const { hierarchy, id } = data[0]

        yield call(fetchPatch, url, { fields: { hierarchy }, approval_doc_ids: [id] })
      }
    }

    yield put(RPMActions.rpmUpsertApprovalDocument(approvalDocument, cb, true))
  } catch (err) {
    console.log('savePlanHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Saving Plan Failed',
      copy: err?.message || err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* copyPlanHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'copy-benefit-plan')
    const url = `${serviceUrl}${api_path}`

    const { api_path: api_path2 } = yield select(getApiPath, 'create-benefit-plan')
    const url2 = `${serviceUrl}${api_path2}`

    const response = yield call(fetchPut, url, payload)
    const { plan_data: { benefit_plan, benefit_limitation, benefit_copay }, plan_id, plan_version } = response?.data[0]
    const { hierarchyIDs, hideHierarchy } = payload
    const approvalDocument = {
      module_name: benefit_plan?.plan_name,
      data: { benefit_plan, benefit_limitation, benefit_copay, doc_id: '', hierarchyIDs, hideHierarchy },
      module: 'BENEFIT_PLAN_DOC',
      status: benefit_plan?.status?.toUpperCase(),
    }


    const cb = function* ({ success, response }) {
      if (success) {
        const { data: doc_data } = response
        const { id: approval_doc_id, hierarchy } = doc_data[0]
        yield call(fetchPut, url2, { plan_id, plan_version, section: 'plan_design_details', model_data: { ...benefit_plan, approval_doc_id, hierarchy }, plan_version: doc_data[0]?.version })
      }
    }

    yield put(RPMActions.rpmUpsertApprovalDocument(approvalDocument, cb, true))

    const transitionalPortal = {
      header: 'Saved Plan Successfully',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  } catch (err) {
    console.log('copyPlanHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Copying Plan Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* createNewPlanVersionHandler({ payload }) {
  try {
    const { approval_doc_id } = payload
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'create-new-plan-version')
    const url = `${serviceUrl}${api_path}`

    const { api_path: api_path2 } = yield select(getApiPath, 'create-benefit-plan')
    const url2 = `${serviceUrl}${api_path2}`

    const response = yield call(fetchPost, url, payload)
    const { plan_data: { benefit_plan, benefit_limitation, benefit_copay }, plan_id, plan_version, hierarchyIDs } = response?.data[0]
    const approvalDocument = {
      module_name: benefit_plan?.plan_name,
      data: { benefit_plan, benefit_limitation, benefit_copay, doc_id: approval_doc_id, hierarchyIDs },
      module: 'BENEFIT_PLAN_DOC',
      status: 'DRAFT',
    }

    const cb = function* ({ success, response }, plan) {
      if (success) {
        const { data: doc_data } = response
        const { version, status, hierarchy, id } = doc_data[0]
        const newDocData = { ...plan, version, status, hierarchy, approval_doc_id: id }
        const qsTemplate = { plan_id: 'plan_id', approval_doc_id: 'approval_doc_id', plan_name: 'plan_name', lob: 'lob', plan_version: 'version', status: 'status', hierarchy: 'hierarchy' }
        Object.keys(qsTemplate).forEach(key => {
          const doc_key = qsTemplate[key]
          qsTemplate[key] = newDocData[doc_key]
        })
        const qs = createQueryString(qsTemplate)
        yield put(NavigationActions.navigateTo({
          pathname: '/create-benefit-plan',
          search: qs,
          state: {
            editMode: true,
          }
        }))
      }
    }

    yield put(RPMActions.rpmUpsertApprovalDocument(approvalDocument, ({ success, response }) => cb({ success, response }, approvalDocument.data.benefit_plan), true))

    const transitionalPortal = {
      header: 'Successfully created new version of the plan',
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  } catch (err) {
    console.log('createNewPlanVersionHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'New Plan Version Plan Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}


function* getBenefitPlanDataHandler({ payload }) {
  try {
    const menuEmpty = yield select(isMenuEmpty)
    if (menuEmpty) {
      yield take([AppTypes.SET_APP_SETTINGS])
    }
    const { serviceUrl } = yield select(getAppState)
    const { userCAG = {} } = yield select(getUserState)
    const { api_path } = yield select(getApiPath, 'benefit-plan-lookup')
    const url = `${serviceUrl}${api_path}`

    let reqBody = {
      carrier: payload?.carrier || '',
      account: payload?.account || '',
      group: payload?.group || '',
      plan_name: payload?.plan_name || '',
      plan_year: payload?.plan_year || '',
      plan_code: payload?.plan_code || '',
      limit: 100,
      offset: 0,
      cag: {
        group: userCAG?.group ?? [],
        account: userCAG?.account ?? [],
        carrier: userCAG?.carrier ?? [],
      },
    }

    const res = yield call(fetchPost, url, reqBody)
    const { data } = res
    yield put(PlanManagementActions.setBenefitPlanData({ data: data[0] }))
  } catch (err) {
    console.log('getBenefitPlanDataHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Get Benefit Plans Failed',
      copy: err?.message ?? err,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* getPlanDesignLookupHandler({ payload }) {
  try {
    const { serviceUrl } = yield select(getAppState)
    const { api_path } = yield select(getApiPath, 'plan-design-lookup')
    const url = `${serviceUrl}${api_path}`

    const reqBody = {
      domain_name: payload?.account || '',
      plan_year: payload?.plan_year || '',
    }
    const res = yield call(fetchPost, url, reqBody)
    const { data } = res
    yield put(PlanManagementActions.setPlanDesignLookupData({ data: data }))
  } catch (err) {
    console.log('getPlanDesignLookupHandler Error >Data ', err)
    const transitionalPortal = {
      header: 'Get PlanDesign Lookup Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

function* benefitPlanSendForReviewHandler({ doc_data, plan_id }) {
  try {
    const cb = function* ({ success, response }) {
      if (success) {
        const { serviceUrl } = yield select(getAppState)
        const { api_path } = yield select(getApiPath, 'update-benefit-plan-by-approval-doc-id')
        const url = `${serviceUrl}${api_path}`
        const { data } = response
        yield call(fetchPatch, url, { status: data[0]?.status, plan_version: data[0]?.version, plan_id })
      }
    }
    yield put(RPMActions.rpmSendForReview(doc_data, cb))
  } catch (err) {
    console.log('benefitPlanSendForReviewHandler Err >Data', err)
    const transitionalPortal = {
      header: 'Benefit Plan Send For Review Failed',
      copy: err.message,
    }
    yield put(AppActions.displayTransitionalPortal(transitionalPortal))
  }
}

// function* benefitPlanUpdateSubdocsHandler({ payload }) {
//   try {
//     const appState = yield select(getAppState)
//     const { data } = yield call(fetchPost, `${appState.apiUrl}/fast/approvals`, payload)

//     const { serviceUrl } = yield select(getAppState)
//     const { api_path } = yield select(getApiPath, 'update-benefit-plan-by-approval-doc-id')
//     const url = `${serviceUrl}${api_path}`
//     yield call(fetchPatch, url, { status: payload?.status, plan_id: data[data?.length - 1]?.module_data?.data?.benefit_plan?.plan_id, plan_version: payload?.plan_version })
//   } catch (err) {
//     console.log('benefitPlanUpdateSubdocsHandler Err >Data', err)
//     const transitionalPortal = {
//       header: 'Benefit Plan Update Subdocs Handler Failed',
//       copy: err.message,
//     }
//     yield put(AppActions.displayTransitionalPortal(transitionalPortal))
//   }
// }


/* HELPERS */
const getFormRendererSections = () => {
  const formRendererSections = [
    'deductibles',
  ]
  return formRendererSections
}
const constructBenefitLimitationData = (data) => {
  const { model_data } = data
  const { limitations, plan_sponsor_cap } = model_data
  if (!!limitations) {
    limitations.forEach((row) => {
      const { age } = row
      row['minimum_age'] = age?.min ?? null
      row['maximum_age'] = age?.max ?? null
    })
  }
  if (!!plan_sponsor_cap) {
    const date_fields = ['start_date', 'end_date']
    plan_sponsor_cap.forEach((row) => {
      date_fields.forEach(field => {
        row[field] = row[field] ? convertStrToDateObj(row[field]) : ''
      })
    })
  }
  return { limitations, plan_sponsor_cap }
}
const constructMixMasterData = (data) => {
  const { model_data } = data
  const { price_source, otc_inclusion, otc_price_source } = model_data
  return { price_source, otc_inclusion, otc_price_source }
}

const constructTranistionData = (data) => {
  const { model_data } = data
  const { transition_fill } = model_data
  return transition_fill
}

const constructCompoundsData = (data) => {
  const { model_data } = data
  const { compound_coverage, allow_scc8, maximum_price_action, minimum_compounds_price, maximum_compounds_price, compound_pharmacy_exclusions, compound_pricing_options, copays = [] } = model_data
  const resultRows = []
  let tierName
  let programName
  let form
  const tierConditions = []
  copays.forEach((tierDataRow) => {
    const {
      accumulate_deductible,
      accumulate_out_of_pocket,
      out_of_pocket_exempt,
      deductible_exempt,
      tier_name,
      program_name,
      pharmacy_type,
      deductible_applies,
      cost_share,
      phase,
    } = tierDataRow
    tierName = tier_name
    programName = program_name
    form = {
      accumulate_deductible,
      accumulate_out_of_pocket,
      out_of_pocket_exempt,
      deductible_exempt,
      deductible_applies
    }
    Object.values(cost_share).forEach((costShareRow) => {
      tierConditions.push({ ...costShareRow, phase: phase ?? '' })
    })
  })
  resultRows.push({
    tier_id: '',
    tier_name: '',
    program_id: '',
    program_name: programName || '',
    form,
    tierConditions
  })
  const copay = Object.values(resultRows).flat()

  return {
    compound_coverage, allow_scc8, minimum_compounds_price, maximum_price_action,
    maximum_compounds_price, compound_pharmacy_exclusions,
    compound_pricing_options, copay
  }
}

const constructRewardsPenaltiesData = (data) => {
  const { model_data } = data
  let { alternate_price_type } = model_data
  if (model_data.isRBP == 'Y') {
    alternate_price_type = 'RBP'
  }
  const { display_coinsurance_penalty_together, display_retail_drugsub_rewards, rbp_cp_apply_rewards_no_intent, rbp_fallback_zone_pricing, rbp_penalty_oop_accumulation, rbp_penalty_predeductible_accumulation, brand_covered_upto_generic_copay_flag } = model_data
  return { alternate_price_type, display_coinsurance_penalty_together, display_retail_drugsub_rewards, rbp_cp_apply_rewards_no_intent, rbp_fallback_zone_pricing, rbp_penalty_oop_accumulation, rbp_penalty_predeductible_accumulation, brand_covered_upto_generic_copay_flag }
}

const constructPhasesData = (data) => {
  const { model_data } = data
  const { phases } = model_data
  return phases;
}

const constructCopayData = (data, section) => {
  const { model_data } = data
  const { copays, effective_start_date, effective_end_date } = model_data
  let groupedData
  if (section === 'formulary_copay') {
    const tierData = getCopayTierData(copays)
    groupedData = _.groupBy(tierData, 'formulary_tier_version_id')
  } else if (section === 'custom_copay') {
    const programData = getCopayProgramData(copays)
    groupedData = _.groupBy(programData, 'program_version_id')
  }

  const ids = Object.keys(groupedData)
  const resultRows = []

  ids.forEach((id) => {
    const tierData = groupedData[id]
    let tierName
    let programName
    let formularyTierVersionId
    let programVersionId
    let form
    const tierConditions = []
    tierData.forEach((tierDataRow) => {
      const {
        accumulate_deductible,
        accumulate_out_of_pocket,
        out_of_pocket_exempt,
        deductible_exempt,
        tier_name,
        program_name,
        formulary_tier_version_id,
        program_version_id,
        pharmacy_type,
        deductible_applies,
        cost_share,
        phase,
      } = tierDataRow
      tierName = tier_name
      programName = program_name
      formularyTierVersionId = formulary_tier_version_id
      programVersionId = program_version_id
      form = {
        accumulate_deductible,
        accumulate_out_of_pocket,
        out_of_pocket_exempt,
        deductible_exempt,
        deductible_applies
      }
      Object.values(cost_share).forEach((costShareRow) => {
        tierConditions.push({ ...costShareRow, phase: phase ?? '' })
      })
    })
    resultRows.push({
      tier_id: section === 'formulary_copay' ? id : '',
      tier_name: tierName || '',
      program_id: section === 'custom_copay' ? id : '',
      program_name: programName || '',
      formulary_tier_version_id: formularyTierVersionId,
      program_version_id: programVersionId,
      form,
      tierConditions,
      effective_start_date,
      effective_end_date
    })
  })
  const resultData = section === 'formulary_copay' ? _.groupBy(resultRows, 'formulary_tier_version_id') : _.groupBy(resultRows, 'program_version_id')
  const flatResultData = Object.values(resultData).flat()
  return flatResultData
}

const constructGrandfatheringData = (data) => {
  const { model_data: { grandfathering: grandfatheringData = [] } } = data
  grandfatheringData.forEach(row => {
    if (!!row['gpi_match_level']) {
      const { gpi_match_level: formattedGPI } = row
      row['gpi_match_level'] = 'GPI' + (formattedGPI.match(/X/g) || []).length
    }
    if (!!row['effective_start_date']) {
      const { effective_start_date } = row
      row['effective_start_date'] = convertStrToDateObj(effective_start_date, { format: 'YYYY-MM-DD HH:mm:ss' }) || ''
    }
    if (!!row['effective_end_date']) {
      const { effective_end_date } = row
      row['effective_end_date'] = convertStrToDateObj(effective_end_date, { format: 'YYYY-MM-DD HH:mm:ss' }) || ''
    }
  })
  return { grandfathering: grandfatheringData }
}

const constructCustomMessagingData = (data) => {
  const { model_data: { custom_messaging: customMessagingData = [] } } = data
  return { custom_messaging: customMessagingData }
}

const getCopayTierData = (copays) => copays.filter(({ tier_id, tier_name, program_name, program_id, formulary_tier_version_id }) => ((!!tier_id && !!tier_name || !!formulary_tier_version_id) || (!!program_name && !program_id && !tier_id && !tier_name && !formulary_tier_version_id)))

const getCopayProgramData = (copays) => copays.filter(({ program_id, program_name, program_version_id }) => (!!program_id || !!program_name || !!program_version_id))

const formatCopayData = (copayData, section, existing, plan_name) => {
  // here
  const tierIndexes = Object.keys(copayData)
  const formattedCopayData = []
  let tierData
  let programData
  let form
  let tierConditions = []
  let phaseData = []
  tierIndexes.forEach((index) => {
    if (section === 'formulary_copay') {
      tierData = copayData[index] ?? {};
      ({ form, tierConditions, phaseData } = tierData)
    } else if (section === 'custom_copay') {
      programData = copayData[index] ?? {};
      ({ form, tierConditions, phaseData } = programData)
    }

    const groupedData = _.groupBy(tierConditions, 'pharmacy_type')

    const pharmacy_types = Object.keys(groupedData)
    if (phaseData?.length > 0) {
      phaseData.forEach((phase) => {
        const groupedData = _.groupBy(phase.tierConditions, 'pharmacy_type')
        const pharmacy_types = Object.keys(groupedData)
        pharmacy_types.forEach((pharmacy_type) => {
          const costShareRows = groupedData[pharmacy_type]
          const costShare = costShareRows.map((row) => _.omit(row, ['action', 'phase']))
          const copayDataRow = {
            tier_id: tierData?.tier_id || '',
            tier_name: tierData?.tier_name || '',
            formulary_tier_version_id: tierData?.formulary_tier_version_id || '',
            program_version_id: programData?.program_version_id || '',
            program_id: programData?.program_id || '',
            program_name: programData?.program_name || '',
            pharmacy_type: "RETAIL",
            cost_share: costShare,
            phase: phase.phase,
            ...form,
          }
          formattedCopayData.push(copayDataRow)
        })
      })
    } else {
      pharmacy_types.forEach((pharmacy_type) => {
        const costShareRows = groupedData[pharmacy_type]
        const costShare = costShareRows.map((row) => _.omit(row, ['action']))
        const copayDataRow = {
          tier_id: tierData?.tier_id || '',
          tier_name: tierData?.tier_name || '',
          formulary_tier_version_id: tierData?.formulary_tier_version_id || '',
          program_version_id: programData?.program_version_id || '',
          program_id: programData?.program_id || '',
          program_name: programData?.program_name || '',
          pharmacy_type: "RETAIL",
          cost_share: costShare,
          ...form,
        }
        formattedCopayData.push(copayDataRow)
      })
    }
  })
  return formattedCopayData
}

const formatCompoundsCopayData = (copayData, section, existing, plan_name) => {
  // here
  const tierIndexes = Object.keys(copayData)
  const formattedCopayData = []
  let tierData
  let programData
  let form
  let tierConditions = []
  let phaseData = []
  tierIndexes.forEach((index) => {
    if (section === 'compounds') {
      programData = copayData[index] ?? {};
      ({ form, tierConditions, phaseData } = programData)
    }

    const groupedData = _.groupBy(tierConditions, 'pharmacy_type')

    const pharmacy_types = Object.keys(groupedData)
    if (phaseData?.length > 0) {
      phaseData.forEach((phase) => {
        const groupedData = _.groupBy(phase.tierConditions, 'pharmacy_type')
        const pharmacy_types = Object.keys(groupedData)
        pharmacy_types.forEach((pharmacy_type) => {
          const costShareRows = groupedData[pharmacy_type]
          const costShare = costShareRows.map((row) => _.omit(row, ['action', 'phase']))
          const copayDataRow = {
            tier_id: '',
            tier_name: '',
            program_id: '',
            program_name: `${plan_name} || Compounds Cost Share` || '',
            pharmacy_type: "RETAIL",
            cost_share: costShare,
            phase: phase.phase,
            ...form,
          }
          formattedCopayData.push(copayDataRow)
        })
      })
    } else {
      pharmacy_types.forEach((pharmacy_type) => {
        const costShareRows = groupedData[pharmacy_type]
        const costShare = costShareRows.map((row) => _.omit(row, ['action']))
        const copayDataRow = {
          tier_id: '',
          tier_name: '',
          program_id: '',
          program_name: `${plan_name} || Compounds Cost Share` || '',
          pharmacy_type: "RETAIL",
          cost_share: costShare,
          ...form,
        }
        formattedCopayData.push(copayDataRow)
      })
    }
  })
  return formattedCopayData
}
const formatDefaultCopayData = (customFormData, currentPlanName) => {
  const cost_share = customFormData.copays.map((row) => _.omit(row, ['action']))
  const copay = {
    deductible_exempt: customFormData.deductible_exempt,
    accumulate_deductible: customFormData.accumulate_deductible,
    accumulate_out_of_pocket: customFormData.accumulate_out_of_pocket,
    out_of_pocket_exempt: customFormData.out_of_pocket_exempt,
    pharmacy_type: '',
    program_id: '',
    program_name: `${currentPlanName} || Default Cost Share`,
    tier_id: '',
    tier_name: '',
    cost_share
  }
  return cost_share.length ? copay : null;
}

const filterOutDefaultCopay = (copays) => {
  if (typeof copays === 'object' &&
    !Array.isArray(copays)) {
    copays = []
  }
  const customCopay = [...copays]
  const filteredData = customCopay.length && customCopay.filter((data) => {
    return (
      (data.program_id && data.program_name) ||
      (data.tier_id && data.tier_name) ||
      (data.program_name && !data.program_name.includes("Default Cost Share"))
    )
  })
  return filteredData;
}

const hasDuplicates = (array, network_tier) => {
  const arr = array.filter(x => x === network_tier)
  return (new Set(arr)).size !== arr.length
}

const hasDuplicateformulary = (formularyList) => {
  const formularyNames = new Set();

  for (const formulary of formularyList) {
    if (formularyNames.has(formulary.formulary_name)) {
      // Duplicate found
      return true;
    } else {
      formularyNames.add(formulary.formulary_name);
    }
  }

  // No duplicates found
  return false;
}


const hasDuplicateProgram = (programList) => {
  const programNames = new Set();

  for (const each_program of programList) {
    if (programNames.has(each_program.program_name)) {
      // Duplicate found
      return true;
    } else {
      programNames.add(each_program.program_name);
    }
  }

  // No duplicates found
  return false;
}

const validateCopayData = (formData) => {
  const networkTierArray = [...formData.tierConditions.map((data) => data.network_tier)];
  let newTierConditions = []
  formData.tierConditions.forEach(tier => {
    const number_fields = ['copay_amount', 'member_pay', 'min_copay', 'max_copay']
    number_fields.forEach((field) => {
      const value = parseFloat(tier[field])
      tier[field] = isNaN(value) ? null : value
    })
    if (tier?.day_supply !== "All" && tier?.pharmacy_type !== "ALL") { newTierConditions.push(tier) }
    if (tier?.day_supply == "All") {
      if (hasDuplicates(networkTierArray, tier?.network_tier)) {
        throw new Error(resolveMessage("val_remove_duplicate_value_from_network_tier").message)
      } else {
        rowCellInfo.day_supply.options.forEach(option => {
          if (option?.value !== "All") {
            const newTierCondition = { ...tier }
            newTierCondition.day_supply = option.value
            newTierConditions.push(newTierCondition)
          }
        })
      }
    }

    if (tier?.copay_amount === null || tier?.effective_end_date == "" || tier?.effective_start_date == "" || tier?.network_tier == "" || tier?.max_copay == "") {
      // TODO: the remove of this Error is temporary for staging fix
      // throw new Error("Error: Undefined Copay Values Exist. Please define copay values or remove condition from copay structure in order to save.")
    }
  })
  const groupped = _.groupBy(newTierConditions, n => `${n.day_supply}${n.network_tier}`)
  const duplicates = (_.uniq(_.flatten(_.filter(groupped, function (n) { return n.length > 1 }))).length > 1)
  if (duplicates) {
    throw new Error(resolveMessage("val_remove_duplicate_value_from_grid").message)
  }
  formData.tierConditions = newTierConditions
}

const convertDropdownObjectsToArray = (formBuilderData, formData, sectionName) => {
  const keys = Object.keys(formData)

  keys.filter(e => !['app_pharmacy_popup_message'].includes(e)).forEach((key) => {
    const value = formData[key]
    // TODO : remove hard coding and move to backend eventually (program, ql, pa, stepTherapy)
    if (key !== 'tiers' && Array.isArray(value) && value.length && !['programRanks', 'qlRanks', 'paRanks', 'stepTherapyRanks', 'accumulator_years', 'deductibles', 'oop'].includes(key)) {
      const selectedIds = getSelectedIds(key, value, formBuilderData, sectionName)
      formData[key] = selectedIds
    }

    // Exceptional case
    if (key === 'formulary_id') {
      formData.formulary_name = formData.formulary_id
    }
  })
}

const getSelectedIds = (key, selectedValues, formBuilderData, sectionName) => {
  const optionKeys = getOptionKeysForDropdowns(key, formBuilderData)

  if (optionKeys) {
    let optionIDKey = ''
    // TODO: Below code was added in haste. Eventually needs to be moved to somewhere else
    if (['utilization_management', 'plan_design_details', 'limitations'].includes(sectionName)) {
      optionIDKey = optionKeys.name
    } else {
      optionIDKey = optionKeys.id
    }
    const selectedIds = []
    selectedValues.forEach((selectedValue) => {
      selectedIds.push(selectedValue[optionIDKey])
    })
    return selectedIds
  }
  return []
}

const getOptionKeysForDropdowns = (key, fields) => {
  const fieldFound = fields.find(({ type, multiple, field }) => (
    type === 'DROPDOWN' && multiple && field === key
  ))
  if (fieldFound) {
    return fieldFound.option_save_keys
  }
  return null
}

const formatPayloadForSingleSelectDropdowns = (formData, details) => {
  const formDataCopy = { ...formData }
  const sections = Object.keys(details)

  const allSingleSelectDropdowns = []
  sections.forEach((section) => {
    const { fields } = details[section]
    // ONLY SINGLE SELECT DROPDOWNS WITH option_save_keys
    const singleSelectDropdowns = fields.filter((fieldObj) => (fieldObj.type === 'DROPDOWN' && fieldObj.multiple === false && fieldObj.option_save_keys))
    if (singleSelectDropdowns.length > 0) {
      allSingleSelectDropdowns.push(...singleSelectDropdowns)
    }
  })

  allSingleSelectDropdowns.forEach((selectFields) => {
    const { option_save_keys, options } = selectFields
    const value = formData[option_save_keys.name]
    formDataCopy[option_save_keys.id] = value
    formDataCopy[option_save_keys.name] = getSelectedItemName(options, value)
  })
  return formDataCopy
}

const getSelectedItemName = (options, value) => {
  const selectedOption = options.find((option) => option.value === value)
  return selectedOption?.display_name
}

const formatPayloadForMultiSelectDropdowns = (formData, details, sectionName) => {
  const formDataCopy = { ...formData }
  const keys = Object.keys(formData)

  // Format dropdown data

  keys.forEach((key) => {
    const value = formData[key]
    // TODO : remove hard coding for utilization mangement (program, pa, ql, stepTherapy) and move to backend eventually
    if (['app_pharmacy_popup_message', 'tiers', 'programRanks', 'paRanks', 'qlRanks', 'stepTherapyRanks'].includes(key) || !Array.isArray(value) || !value.length) {
      return
    }
    const res = []
    const optionKeys = getOptionKeys(key, details, sectionName)
    if (!optionKeys) return;
    value.forEach((selectedVal) => {
      let selectedOpt = {}
      // TODO: remove hard coding and move to somewhere else fetch this change from the backend instead of the front end
      if (['utilization_management'].includes(sectionName)) {
        selectedOpt = {
          [optionKeys.name]: selectedVal,
          [optionKeys.id]: getDocId(key, selectedVal, details, sectionName),
        }
      } else {
        selectedOpt = {
          [optionKeys.name]: getDisplayName(key, selectedVal, details, sectionName),
          [optionKeys.id]: selectedVal,
        }
      }
      res.push(selectedOpt)
    })
    formDataCopy[key] = res
  })
  return formDataCopy
}

const getDisplayName = (key, selectedVal, details, sectionName) => {
  const sectionDetails = details[sectionName]
  const fieldFound = sectionDetails.fields.find(({ type, multiple, field }) => (
    type === 'DROPDOWN' && multiple && field === key
  ))
  if (fieldFound) {
    const { options } = fieldFound
    const selectedOption = options.find(({ value }) => value === selectedVal)
    if (selectedOption) {
      return selectedOption.display_name
    }
  }
  return null
}

const getDocId = (key, selectedVal, details, sectionName) => {
  const sectionDetails = details[sectionName]
  const fieldFound = sectionDetails.fields.find(({ type, multiple, field }) => (
    type === 'DROPDOWN' && multiple && field === key
  ))
  if (fieldFound) {
    const { options } = fieldFound
    const selectedOption = options.find(({ value }) => value === selectedVal)
    if (selectedOption) {
      return selectedOption.doc_id
    }
  }
  return null
}

const getOptionKeys = (key, details, sectionName) => {
  const sectionDetails = details[sectionName]
  const fieldFound = sectionDetails.fields.find(({ type, multiple, field }) => (
    type === 'DROPDOWN' && multiple && field === key
  ))
  if (fieldFound) {
    return fieldFound.option_save_keys
  }
  return null
}

const validateFields = (validationFields, data) => {
  validationFields.forEach(fieldEntry => {
    const { field, required, display_name } = fieldEntry
    if ((field && required && field in data && (data[field] === '' || data[field] === undefined))) {
      const value = display_name || field
      throw new Error(resolveMessage("val_missing_required_data_element", { value: value }).message)
    }
  })
}
